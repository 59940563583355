import { adminClient } from "@api/admin/AdminClient";
import { Box, Container, Dialog, DialogContent, ListItemIcon, MenuItem, Typography } from "@material-ui/core";
import BarChartIcon from '@material-ui/icons/BarChart';
import { ComputedDatum, ResponsiveBar } from "@nivo/bar";
import { AdminItemsItemViewCountPostResponse } from "@reshopper/admin-client";
import { toDanishDateFormat } from "@utils/miscellaneous";
import { useEffect, useState } from "react";


export default function ViewCountMenuItemComponent(props:
	{
		itemId: string,
		onClose: () => void
	}
): JSX.Element {
	const [dialogOpen, setDialogOpen] = useState(false);
	const [graphData, setGraphData] = useState<any[]>(new Array<any>());
	const theme = {
		labels: {
		text: { fontSize: 26 ?? 14 }
	}, axis: {
		ticks: {
			text: {
				fontSize:  14 ?? 14
			}
		}
	}
};

	function handleOpen() {
		setDialogOpen(true);
		props.onClose();
	}
	
	async function fetchViewCountMenuItemComponent() {
		const viewCountBoostTimesResponse = await adminClient().adminItemsItemViewCountPost({ id: props.itemId })
		const generatedGraphData = generateGraphData(viewCountBoostTimesResponse);
		setGraphData(generatedGraphData);
	}

	const getColor = (bar: ComputedDatum<any>) => {
		// If boostedOnDay === true, return green else blue
		if (bar.data.z) {
			return "#4BB567";
		}
		return "#62AED6";	
	};

	useEffect(
		() => {
			fetchViewCountMenuItemComponent();
		},
		[]);

	function generateGraphData(viewCountBoostTimes: AdminItemsItemViewCountPostResponse) {
		return viewCountBoostTimes.map(viewCountBoostTime => {
			return {
				x: toDanishDateFormat(viewCountBoostTime.timeUtc as Date, "ccc dd/MM"),
				y: +viewCountBoostTime.viewCount,
				z: viewCountBoostTime.boostedOnDay
			} as any;
		})
	} 
	return <>
		<MenuItem button
			onClick={handleOpen}
		>
			<ListItemIcon>
				<BarChartIcon />
			</ListItemIcon>
			View Count Stacked Bar
		</MenuItem>
		{graphData && <Dialog
			fullWidth
			maxWidth="xl"
			open={dialogOpen}
			onClose={() => setDialogOpen(false)}
			
		> 
			<Box m={0}>
				<Container
					maxWidth={false}
					style={{
						height: 600,
						width: "100%",
						padding: 0,
						marginTop: -8
					}}>
					<ResponsiveBar
						data={graphData}
						keys={["y"]}
						indexBy="x"
						labelSkipHeight={26}
						valueFormat={value => `${Number(value).toLocaleString('da-DK')}`}
						margin={{ top: 40, bottom: 40, left: 40, right: 40 }}
						colors={getColor}
						axisBottom={{
							tickSize: 5,
							tickPadding: 5,
						}}
						axisLeft={{
							tickSize: 0,
							tickPadding: 5,
							tickRotation: 0
						}}
						labelTextColor="#ffffff"
						theme={theme}
						
					/>
				</Container>
			</Box>
			<DialogContent><Typography
						variant="body1"
						align="center"
						style={{ fontWeight: "bolder", whiteSpace: 'pre-line' }}>
						If the Item is boosted, The bar will appear green.      
					</Typography>
					<Typography
						variant="body1"
						align="center"
						style={{ fontWeight: "bolder", whiteSpace: 'pre-line' }}>
						   The Stack Bar will display the last 30 days where the item have been viewed.
					</Typography>
			</DialogContent>
		</Dialog>}
	</>
}

